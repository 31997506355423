<template>
  <div
    v-if="content && content.length > 0"
  >
    <AppBff
      v-if="cccxContext?.isBff"
      :content="content"
      :context="cccAppContext"
      :scene-data="sceneData"
      :cate-links="cateLinks"
    />
    <AppCcc 
      v-else
      :content="content"
      :scene-data="sceneData"
      :context="cccAppContext"
      :cate-links="cateLinks"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import AppBff from 'public/src/pages/ccc-home/components/Index.vue'

export default {
  name: 'CccxComp',
  components: {
    AppCcc,
    AppBff
  },
  props: {
    cccxContext: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState([
      'PICKS_LINKS',
    ]),
    ...mapGetters([
      'locals',
    ]),
    sceneData() {
      const { pageType = '', id = '' } = this.cccxContext || {}
      return {
        sceneName: pageType, // 埋点需要
        pageType: pageType, // 埋点需要
        pageFrom: pageType, // 标识应用在哪个页面，你也可以直接取ccc后台返的pageType
        id,
      }
    },
    cateLinks() {
      return this.cccxContext?.cateLinks || {}
    },
    cccAppContext() {
      const { WEB_CLIENT } = this.locals || {}
      return {
        WEB_CLIENT,
        PICKS_GLOBAL_INFO: {
          LINKS: this.PICKS_LINKS,
        }
      }
    },
  },
}
</script>
