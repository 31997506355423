import schttp from 'public/src/services/schttp'
import { getQueryString } from '@shein/common-function'

let promise = null

/**
 * @description: 触发预请求不贵数据
 */
export const triggerPreFetchThriftyFind = () => {
  promise = promise || preFetchThriftyFind()
}

/**
 * @description: 获取预请求不贵数据
 * @returns {Promise}
 * */
export const getPreFetchThriftyFind = () => {
  if (!promise) {
    triggerPreFetchThriftyFind()
  }
  return promise
}


export function isThriftyFindPage () {
  if (typeof window === 'undefined' || !window.SaPageInfo) return false
  return window.SaPageInfo.page_name === 'page_super_deals'
}

export function getThriftyFindPage() {
  return schttp({
    url: `/api/thriftyFind/pageData/get${window.location.search}`,
    method: 'GET',
  })
}

/**
 * @description: 预请求不贵数据
 * */
function preFetchThriftyFind() {
  if (typeof window === 'undefined') return Promise.resolve()
  if (!isThriftyFindPage()) return Promise.resolve()
  if (getQueryString({ key: 'show_skeleton' }) === '1') return Promise.resolve()

  // 禁止浏览器滚动上一次的位置
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }

  const data = getThriftyFindPage().catch((e) => {
    console.error(e)
  })
  return data || {}
}
